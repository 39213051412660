export function passToStoreHandler(this: any, eventName: string, event: any) {
  if (!eventName.startsWith("SOCKET_")) {
    return;
  }

  let method = "commit",
    target = eventName.toUpperCase(),
    msg: any = {};

  // const successResponses = ["success", "event", "hangup"],
  //   failureResponses = ["error"],
  //   restoreMessages = ["timeout"];

  if (this.format === "json" && event.data) {
    try {
      msg = JSON.parse(event.data);
      msg = underscoreToCamelCase(msg);
    } catch {
      msg.error = event.data;
    } finally {
      if (msg.mutation) {
        target = [msg.namespace || "", msg.mutation].filter(e => !!e).join("/");
      } else if (msg.action) {
        method = "dispatch";
        target = [msg.namespace || "", msg.action].filter(e => !!e).join("/");
      } else if (msg.command) {
        const comm = msg.command;

        if (comm.sdp) {
          const sdp = comm.sdp;

          if (sdp.type === "offer") {
            method = "dispatch";
            target = "setGSTJSEPOffer";
            msg = sdp;
          }
        } else if (comm.candidate) {
          const candidate = comm.candidate;

          method = "dispatch";
          target = "addIceCandidate";
          msg = {
            deviceId: msg.deviceId,
            candidate: {
              candidate: candidate.candidate,
              sdpMLineIndex: candidate.sdpMlineIndex,
              sdpMid: candidate.sdpMid
            }
          };
        } else if (comm.seekResponse) {
          if (comm.seekResponse.success) {
            console.log("SEEK RESPONSE: " + JSON.stringify(comm.seekResponse));
          } else {
            console.log("ERROR PROCESSING SEEK COMMAND");
          }
        }
      } else if (msg.error) {
        if (msg.error.toLowerCase() === "unauthorized") {
          console.log("UNAUTHORIZED USER");
          method = "dispatch";
          target = "resetGSTState";
          msg = msg.error;
        } else if (
          msg.error.toLowerCase() === "no connection found for camera"
        ) {
          console.log("No CONNECTION");
          method = "dispatch";
          // target = "refreshGSTState";
          msg = msg.error;
        }
      }
      if (msg.response) {
        const is_ack = msg.ack ? "" : "NOT";
        console.log(
          "OPERATION " + is_ack + " ACKNOWLEDGED - Message: " + msg.response
        );
      }
      this.store[method](target, msg);
    }
  } else {
    this.store[method](target, event);
  }
}

function underscoreToCamelCase(data) {
  if (typeof data != "object") return data;

  for (const oldName in data) {
    // Camel to underscore
    const newName = oldName.replace(/([-_][a-z])/gi, str => {
      return str
        .toUpperCase()
        .replace("-", "")
        .replace("_", "");
    });

    // Only process if names are different
    if (newName != oldName) {
      // Check for the old property name to avoid a ReferenceError in strict mode.
      if (Object.prototype.hasOwnProperty.call(data, oldName)) {
        data[newName] = data[oldName];
        delete data[oldName];
      }
    }

    // Recursion
    if (typeof data[newName] == "object") {
      data[newName] = underscoreToCamelCase(data[newName]);
    }
  }
  return data;
}
