import { render, staticRenderFns } from "./VideoControlsComponent.vue?vue&type=template&id=10247496&"
import script from "./VideoControlsComponent.vue?vue&type=script&lang=ts&"
export * from "./VideoControlsComponent.vue?vue&type=script&lang=ts&"
import style0 from "./VideoControlsComponent.scss?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../edge-video-system-py/html/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports