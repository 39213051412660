<template>
  <div id="app" data-app>
    <v-app-bar
      v-if="showToolbar"
      dense
      color="white"
    >
      <v-toolbar-title>Mi|iM Camera Viewer</v-toolbar-title>
    </v-app-bar>
    <!-- <div id="nav"> -->
      <!-- <router-link to="/">Home</router-link> | -->
      <!-- <router-link to="/about">About</router-link> -->
    <!-- </div> -->
    <router-view/>
  </div>
</template>

<script>
// @ is an alias to /src=
export default {
  name: 'App',
  computed: {
    showToolbar() {
      const toolbarViews = ["Home"]

      return toolbarViews.indexOf(this.$route.name || []) > -1;
    }
  },
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
