<template>
  <div class="VideoFullscreenView">
    <GSTWebRTCVideoPlayer :videos="chosenVideoList" :showControls="false" :onlyVideo="true"></GSTWebRTCVideoPlayer>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapActions, mapGetters } from "vuex";

export default {
  name: 'VideoFullscreenView',
  mounted() {
    this.initiate()
  },
  data() {
    return {
      camera: undefined
    }
  },
  computed: {
    ...mapGetters("cameraConfigStore", {}),
    chosenVideoList() {
      if ( this.camera ) {
        return this.camera
      }
      return [];
    }
  },
  methods: {
    ...mapActions("cameraConfigStore", {
      getCamera: "getCamera",
      getIceConfig: "getIceConfig"
    }),
    async initiate() {
      const deviceId = this.$route.params.device_id;
      await this.getIceConfig()

      this.$store.dispatch("startGSTConnection");
      this.camera = await this.getCamera(deviceId);
    }
  }
}
</script>

<style>
.gst-video-player__card {
  padding: 0px !important;
}
</style>
