import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import VideoFullscreenView from '../views/VideoFullscreenView.vue'
import VideoControlsView from '../views/VideoControlsView.vue'
import VideoView from '../views/VideoView.vue'
import store from "../store/index";

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    meta: { requiredAuth: true },
    component: Home
  },
  {
    path: '/video/:device_id',
    component: VideoView,
    meta: { requiredAuth: true },
    children: [
      {
        path: 'fullscreen',
        component: VideoFullscreenView,
        meta: { requiredAuth: true },
      },
      {
        path: 'controls',
        component: VideoControlsView,
        meta: { requiredAuth: true },
      }
    ]
  },
  {
    path: '/login',
    name: 'Login',
    meta: { requiredAuth: false },
    component: Login
  }
]
// http://localhost:8080/?refresh_token=al-kh9sDYcq1kmJJUoUEY5mUwOofsjtvopp0msPo6x0&tenant_id=shop_poc

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  if (to.meta.requiredAuth) {
    const refreshData = {
      refreshToken: to.query.refresh_token ? to.query.refresh_token : undefined,
      tenantId: to.query.tenant_id ? to.query.tenant_id : undefined
    };

    await store.dispatch("authStore/checkAuthentication", refreshData)

    const isAuthenticated = store.getters["authStore/isAuthenticated"];
    
    if (isAuthenticated ) {
      return next();
    }
    else {
      return next({ path: "/login" });
    }
  }
  return next();
});

export default router
