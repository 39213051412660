import Vue from 'vue';
// import VueCompositionAPI from '@vue/composition-api';
import App from './App.vue';
import wwwVideoPlayer from '@aicradle/www-video-player';
// import wwwVideoPlayer from 'www-video-player';
import store from './store';
import './registerServiceWorker';
import router from './router';
import vuetify from '@/plugins/vuetify';
// Vue.use(VueCompositionAPI);

Vue.config.productionTip = false

Vue.use(wwwVideoPlayer, {store, parentHasCompositionAPI: false})

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
