<template>
  <div class="home">
    <!-- <img alt="Vue logo" src="../assets/logo.png"> -->
    <GSTWebRTCTiles></GSTWebRTCTiles>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapActions, mapGetters } from "vuex";

export default {
  name: 'Home',
  mounted() {
    this.initiate()
  },
  computed: {
    ...mapGetters("cameraConfigStore", {
      cameraConfig: "getCameraConfig",
    }),
  },
  methods: {
    ...mapActions("cameraConfigStore", {
      getCameraConfig: "getCameraConfig",
      getIceConfig: "getIceConfig"
    }),
    async initiate() {
      await this.getCameraConfig()
      await this.getIceConfig()
    }
  }
}
</script>
