
































































































































































































import {
  computed,
  ComputedRef,
  ref,
  reactive,
  onMounted
} from "@vue/composition-api";
import {
  VideoState,
  VideoManager,
  UseVideoControl,
  Position,
  TimeInterval,
  UseVideoTime
} from "../../types/VideoPlayerInterface";

const HOUR = 3600,
  SECOND = 60,
  MILLISECOND = 1000,
  DEFAULT_TIME_FRAME = HOUR / 6,
  DEFAULT_INTERVAL_TIME = HOUR / 12,
  MIN_MINI_TIMELINE_WIDTH = 3,
  INTERVAL_WIDTH = 3,
  INTERVAL_SCRUBBER_OFFSET = 3,
  RESIZE_DELAY = 100,
  INTERVAL_RATE = 1000,
  DEFAULT_RATE = 2;

export default {
  props: {
    video: {
      type: Object,
      required: true
    },
    skipInterval: Number,
    playbackRate: Number
  },
  data: () => ({
    defaultRate: {
      text: "1X",
      value: 1
    },
    rateOptions: [
      {
        text: "1X",
        value: 1
      },
      {
        text: "2X",
        value: 2
      },
      {
        text: "4X",
        value: 4
      },
      {
        text: "10X",
        value: 10
      }
    ]
  }),
  setup(props: any, context: any) {
    const videoObject: VideoState = props.video.state;

    videoObject.endTime = Math.round(Date.now() / MILLISECOND);
    videoObject.startTime = videoObject.endTime - DEFAULT_TIME_FRAME;
    videoObject.recordedEndTime = videoObject.endTime;
    videoObject.recordedStartTime = videoObject.startTime;
    videoObject.duration = videoObject.endTime - videoObject.startTime;

    if (videoObject.isLive) {
      const intervalEndTime = videoObject.endTime,
        intervalStartTime =
          intervalEndTime -
          (videoObject.intervalRange || DEFAULT_INTERVAL_TIME);

      videoObject.intervalEndTime = intervalEndTime;
      videoObject.intervalStartTime = intervalStartTime;
      videoObject.intervalRange = intervalEndTime - intervalStartTime;
    }

    context.emit("updateVideo", videoObject);

    const videoControl: UseVideoControl = useVideoControl(props, context);
    const videoTimeline = useVideoTimeline(props, context);

    onMounted(() => {
      window.addEventListener(
        "resize",
        _debounce(RESIZE_DELAY, () => {
          const newWidth =
            videoTimeline.miniTimelineRef.value &&
            videoTimeline.miniTimelineRef.value.offsetWidth
              ? videoTimeline.miniTimelineRef.value.offsetWidth
              : 0;

          if (newWidth && newWidth !== videoTimeline.timelineWidth.value) {
            videoTimeline.timelineWidth.value = newWidth;
          }
        })
      );
    });

    return {
      ...videoControl,
      ...videoTimeline
    };
  }
};

function useVideoControl(props: any, context: any): UseVideoControl {
  const rewindRate: ComputedRef<number> = computed(() => DEFAULT_RATE),
    fastForwardRate: ComputedRef<number> = computed(() => DEFAULT_RATE),
    video: VideoManager = reactive(props.video),
    isLive: ComputedRef<boolean> = computed(() => {
      return video.state.isLive;
    });

  let rewindInterval: any = null,
    fastForwardInterval: any = null,
    pauseInterval: any = null;

  function play(): void {
    const videoElements: HTMLMediaElement[] = video.elements,
      videoObject: VideoState = video.state;

    if (videoElements && !videoObject.isPlaying) {
      _clearIntervals();

      videoObject.isTrickmode = false;

      videoElements.forEach((video: HTMLMediaElement) => {
        video.play();
      });

      const timestamp =
        (videoObject.recordedEndTime -
          (videoObject.recordedEndTime -
            videoObject.recordedStartTime -
            videoObject.currentTime)) *
        1000000;

      context.root.$store.dispatch("switchGSTWebRTCMode", {
        command: {
          // timestamp: timestamp + 10000000,
          type: "resume"
        },
        deviceId: props.video.id
      });

      videoObject.isPlaying = true;
      context.emit("updateVideo", videoObject);
    }
  }

  const pause = (): void => {
    const videoElements: HTMLMediaElement[] = video.elements,
      videoObject: VideoState = video.state,
      isLive: boolean = videoObject.isLive;

    if (videoElements[0] && videoObject.isPlaying) {
      console.log("PAUSING");
      videoObject.isTrickmode = true;

      if (isLive) {
        videoObject.endTime = Math.round(Date.now() / MILLISECOND);
        videoObject.startTime = videoObject.endTime - DEFAULT_TIME_FRAME;
        videoObject.recordedEndTime = videoObject.endTime;
        videoObject.recordedStartTime = videoObject.startTime;
        videoObject.intervalEndTime = videoObject.endTime;
        videoObject.intervalStartTime =
          videoObject.endTime - DEFAULT_INTERVAL_TIME;
        videoObject.currentTime = videoObject.endTime - videoObject.startTime;

        videoElements.forEach((video: HTMLMediaElement) => {
          video.pause();
        });
      }
      // else {
      //   console.log( "ELSE" )
      //   videoObject.currentTime = videoElements[0].currentTime;
      // }
      const timestamp =
        (videoObject.recordedEndTime -
          (videoObject.recordedEndTime -
            videoObject.recordedStartTime -
            videoObject.currentTime)) *
        1000000;

      videoObject.recordedPosition = timestamp / 1000000;

      context.root.$store.dispatch("switchGSTWebRTCMode", {
        command: {
          // timestamp: timestamp + 10000000,
          type: "pause"
        },
        deviceId: props.video.id
      });

      _clearIntervals();

      videoObject.isLive = false;
      videoObject.isPlaying = false;
      context.emit("updateVideo", videoObject);

      _pauseIntervals();
    }
  };

  const skipBack = (): void => {
    const videoElements: HTMLMediaElement[] = video.elements,
      skipInterval: number = props.skipInterval,
      videoObject: VideoState = video.state;

    if (videoElements[0]) {
      const newTime: number = Math.max(
        videoObject.leftTimeFrame || 0,
        _intervalTimeFromStart.value,
        videoObject.currentTime - skipInterval
      );

      if (isLive.value) {
        videoObject.endTime = Math.round(Date.now() / MILLISECOND);
        videoObject.startTime = videoObject.endTime - DEFAULT_TIME_FRAME;
        videoObject.recordedEndTime = videoObject.endTime;
        videoObject.recordedStartTime = videoObject.startTime;
        videoObject.intervalEndTime = videoObject.endTime;
        videoObject.intervalStartTime =
          videoObject.endTime - DEFAULT_INTERVAL_TIME;
        videoObject.isLive = false;
      }

      _clearIntervals();

      videoObject.currentTime = newTime;
      videoElements.forEach((video: HTMLMediaElement) => {
        video.currentTime = newTime;
      });
      context.emit("updateVideo", videoObject);
    }
  };

  const jumpBack = (): void => {
    const videoElements: HTMLMediaElement[] = video.elements,
      videoObject: VideoState = video.state;

    if (videoElements[0]) {
      const newTime: number = Math.max(
        1,
        _intervalTimeFromStart.value,
        videoObject.leftTimeFrame || 0
      );

      if (isLive.value) {
        videoObject.endTime = Math.round(Date.now() / MILLISECOND);
        videoObject.startTime = videoObject.endTime - DEFAULT_TIME_FRAME;
        videoObject.recordedEndTime = videoObject.endTime;
        videoObject.recordedStartTime = videoObject.startTime;
        videoObject.intervalEndTime = videoObject.endTime;
        videoObject.intervalStartTime =
          videoObject.endTime - DEFAULT_INTERVAL_TIME;
        videoObject.isLive = false;
      }

      _clearIntervals();

      videoObject.currentTime = newTime;

      videoElements.forEach((video: HTMLMediaElement) => {
        video.currentTime = newTime;
      });
      context.emit("updateVideo", videoObject);
    }
  };

  const rewind = (): void => {
    const videoElements: HTMLMediaElement[] = video.elements,
      videoObject: VideoState = video.state;

    if (videoElements[0]) {
      _clearIntervals();

      videoObject.isTrickmode = true;

      if (isLive.value) {
        videoObject.endTime = Math.round(Date.now() / MILLISECOND);
        videoObject.startTime = videoObject.endTime - DEFAULT_TIME_FRAME;
        videoObject.recordedEndTime = videoObject.endTime;
        videoObject.recordedStartTime = videoObject.startTime;
        videoObject.intervalEndTime = videoObject.endTime;
        videoObject.intervalStartTime =
          videoObject.endTime - DEFAULT_INTERVAL_TIME;
        videoObject.isLive = false;
      }

      rewindInterval = setInterval(_rewindInterval, INTERVAL_RATE);
      // pause();
      // rewindInterval = setInterval(_rewindInterval, INTERVAL_RATE);
      const timestamp =
        (videoObject.recordedEndTime -
          (videoObject.recordedEndTime -
            videoObject.recordedStartTime -
            videoObject.currentTime)) *
        1000000;

      videoObject.recordedPosition = timestamp / 1000000;

      context.root.$store.dispatch("switchGSTWebRTCMode", {
        command: {
          seek_command: {
            // Multiply by 1K to get nanos
            to_timestamp: (timestamp + 10000000) * 1000, // Why are we adding 10 sec?
            rate: videoObject.playbackRate * -1,
            key_frames_only: true, // Hardcoded to test player
            target_state: "playing"
          },
          type: "seek"
        },
        deviceId: props.video.id
      });
    }
  };

  const skipForward = (): void => {
    const videoElements: HTMLMediaElement[] = video.elements,
      skipInterval: number = props.skipInterval,
      videoObject: VideoState = video.state;

    if (isLive.value) {
      return;
    }

    if (videoElements[0]) {
      const timeArray: number[] = [
        videoElements[0].currentTime + skipInterval,
        videoObject.intervalEndTime - videoObject.recordedStartTime
      ];

      let newTime = 0;

      if (videoObject.rightTimeFrame) {
        timeArray.push(videoObject.rightTimeFrame);
      }

      newTime = Math.min(...timeArray);

      _clearIntervals();

      videoObject.currentTime = newTime;
      videoElements.forEach((video: HTMLMediaElement) => {
        video.currentTime = newTime;
      });
      context.emit("updateVideo", videoObject);
    }
  };

  const jumpForward = (): void => {
    const videoElements: HTMLMediaElement[] = video.elements,
      videoObject: VideoState = video.state;

    if (isLive.value) {
      return;
    }

    if (videoElements[0]) {
      const timeArray: number[] = [
        videoObject.duration,
        videoObject.intervalEndTime - videoObject.recordedStartTime
      ];

      let newTime = 0;

      if (videoObject.rightTimeFrame) {
        timeArray.push(videoObject.rightTimeFrame);
      }

      newTime = Math.min(...timeArray);

      _clearIntervals();

      videoObject.currentTime = newTime;
      videoElements.forEach((video: HTMLMediaElement) => {
        video.currentTime = newTime;
      });
      context.emit("updateVideo", videoObject);
    }
  };

  const fastForward = (): void => {
    const videoElements: HTMLMediaElement[] = video.elements,
      videoObject: VideoState = video.state;

    if (isLive.value) {
      return;
    }

    if (videoElements[0]) {
      _clearIntervals();

      videoObject.isTrickmode = true;

      if (isLive) {
        videoObject.endTime = Math.round(Date.now() / MILLISECOND);
        videoObject.startTime = videoObject.endTime - DEFAULT_TIME_FRAME;
        videoObject.recordedEndTime = videoObject.endTime;
        videoObject.recordedStartTime = videoObject.startTime;
        videoObject.isLive = false;
      }

      // pause();
      fastForwardInterval = setInterval(_fastForwardInterval, INTERVAL_RATE);
      const timestamp =
        (videoObject.recordedEndTime -
          (videoObject.recordedEndTime -
            videoObject.recordedStartTime -
            videoObject.currentTime)) *
        1000000;

      // videoObject.recordedPosition = timestamp / 1000000;

      context.root.$store.dispatch("switchGSTWebRTCMode", {
        command: {
          seek_command: {
            // Multiply by 1K to get nanos
            to_timestamp: (timestamp + 10000000) * 1000, // Why are we adding 10 sec?
            rate: videoObject.playbackRate,
            target_state: "playing"
          },
          type: "seek"
        },
        deviceId: props.video.id
      });
    }
  };

  const liveClick = (): void => {
    const videoObject: VideoState = video.state;

    if (!isLive.value) {
      const endTime: number = Math.round(Date.now() / MILLISECOND),
        startTime: number =
          Math.round(Date.now() / MILLISECOND) - DEFAULT_TIME_FRAME;

      videoObject.isTrickmode = false;

      videoObject.recordedEndTime = endTime;
      videoObject.intervalEndTime = endTime;
      videoObject.endTime = endTime;
      videoObject.recordedStartTime = startTime;
      videoObject.startTime = startTime;
      videoObject.intervalStartTime = endTime - DEFAULT_INTERVAL_TIME;
      videoObject.isLive = true;
      play();
      context.root.$store.dispatch("switchGSTWebRTCMode", {
        command: {
          type: "live"
        },
        deviceId: props.video.id
      });
      context.emit("updateVideo", videoObject);
    }
  };

  const _intervalTimeFromStart: ComputedRef<number> = computed(() => {
    const videoObject: VideoState = video.state;

    return videoObject.intervalStartTime - videoObject.recordedStartTime;
  });

  const _rewindInterval = (): void => {
    const videoElements: HTMLMediaElement[] = video.elements,
      videoObject: VideoState = video.state,
      isLive: boolean = videoObject.isLive,
      newTime: number = isLive
        ? videoObject.duration - rewindRate.value
        : videoElements[0].currentTime - rewindRate.value,
      startTime = 0,
      targetTime: number = Math.max(startTime, newTime);

    if (newTime <= startTime || !videoObject.isTrickmode) {
      clearInterval(rewindInterval);
      rewindInterval = null;
    }

    videoObject.currentTime = targetTime;
    // videoElements.forEach((video: HTMLMediaElement) => {
    //   video.currentTime = targetTime;
    // });
    context.emit("updateVideo", videoObject);
  };

  const _fastForwardInterval = (): void => {
    // const videoElements: HTMLMediaElement[] = video.elements,
    const videoObject: VideoState = video.state,
      newTime: number = videoObject.currentTime + fastForwardRate.value,
      endTime: number = videoObject.endTime - videoObject.startTime,
      targetTime: number = Math.min(newTime, endTime);

    if (
      newTime >= videoObject.intervalEndTime - videoObject.recordedStartTime ||
      newTime >= endTime ||
      !videoObject.isTrickmode
    ) {
      clearInterval(fastForwardInterval);
      rewindInterval = null;
    }

    videoObject.currentTime = targetTime;
    videoObject.recordedPosition = targetTime + videoObject.recordedStartTime;
    // videoElements.forEach((video: HTMLMediaElement) => {
    //   video.currentTime = targetTime;
    // });
    context.emit("updateVideo", videoObject);
  };

  const _pauseIntervals = (): void => {
    const videoObject: VideoState = video.state;
    console.log("PAUSING", videoObject.isPlaying, props.video.elements.length);

    if (!videoObject.isPlaying && props.video.elements.length) {
      context.emit("timeUpdate", -1);
      setTimeout(_pauseIntervals, 1000);
    }
  };

  const _clearIntervals = (): void => {
    if (rewindInterval) {
      clearInterval(rewindInterval);
      rewindInterval = null;
    }

    if (fastForwardInterval) {
      clearInterval(fastForwardInterval);
      fastForwardInterval = null;
    }

    if (pauseInterval) {
      clearInterval(pauseInterval);
      pauseInterval = null;
    }
  };

  return {
    play,
    pause,
    rewind,
    fastForward,
    jumpBack,
    jumpForward,
    skipBack,
    skipForward,
    liveClick,
    isLive
  };
}

function useVideoTimeline(props: any, context: any): UseVideoTime {
  const video: VideoManager = reactive(props.video),
    hoverTime = ref<number>(0),
    hoverX = ref<number>(0),
    dragX = ref<number>(0),
    timelineWidth = ref<number>(0),
    timeFrameFlipped = ref<boolean>(false),
    startAtBeginningInterval = ref<boolean>(false),
    pauseToDrag = ref<boolean>(false),
    progressHover = ref<boolean>(false),
    indicatorRef = ref<HTMLElement>(),
    miniTimelineRef = ref<HTMLElement>(),
    timelineBarRef = ref<HTMLElement>(),
    scrubber = ref<HTMLElement>(),
    timeFrame = ref<HTMLElement>(),
    rightTimeFrame = ref<HTMLElement>(),
    rateValueRef = ref<HTMLElement>();

  const isResizing: ComputedRef<boolean> = computed(() => {
    return video.state.isResizing;
  });

  const isDragging: ComputedRef<boolean> = computed(() => {
    return video.state.isDragging;
  });

  const isSelectingTimeFrame: ComputedRef<boolean> = computed(() => {
    return video.state.isSelectingTimeFrame;
  });

  const currentIntervalRange: ComputedRef<number> = computed(() => {
    const videoObject: VideoState = video.state;

    return videoObject.intervalRange || DEFAULT_INTERVAL_TIME;
  });

  const miniTimelinePosition: ComputedRef<Position> = computed(() => {
    const videoObject: VideoState = video.state,
      videoElements: HTMLMediaElement[] = video.elements,
      position: Position = {
        left: "",
        width: ""
      };

    _calculateTimelineWidth();

    if (
      videoObject.intervalStartTime ||
      (videoElements[0] && videoElements[0].currentTime) ||
      videoElements[0].currentTime ||
      timelineWidth.value
    ) {
      position.left = `${((videoObject.intervalStartTime -
        videoObject.startTime) /
        videoObject.duration) *
        timelineWidth.value}px`;
      position.width = `${Math.max(
        ((videoObject.intervalEndTime - videoObject.intervalStartTime) /
          videoObject.duration) *
          timelineWidth.value,
        MIN_MINI_TIMELINE_WIDTH
      )}px`;
    }
    return position;
  });

  const scrubberPosition: ComputedRef<string> = computed(() => {
    const videoObject: VideoState = video.state,
      videoElements: HTMLMediaElement[] = video.elements,
      isLive: boolean = videoObject.isLive,
      intervalDuration: number =
        videoObject.intervalEndTime - videoObject.intervalStartTime;

    if (isLive) {
      return "left: 100%;";
    }

    if (
      !videoObject.currentTime &&
      !videoElements[0].currentTime &&
      !intervalDuration &&
      !intervalMap
    ) {
      return "left: 0;";
    } else if (videoObject.isDragging) {
      const barWidth: number = _getHTMLElementWidth(indicatorRef.value);

      if (dragX.value < 0) {
        return "left: 0;";
      } else if (dragX.value > barWidth) {
        return "left: 100%;";
      }

      return `left: ${(dragX.value / barWidth) * 100}%;`;
    } else {
      // console.log("CURRENT TIME", videoObject.currentTime, videoObject.recordedPosition);
      const percentage: number = Math.min(
          100,
          ((videoObject.currentTime - _intervalTimeFromStart.value) /
            intervalDuration) *
            100
        ),
        string = `left: ${percentage}%;`;

      return string;
    }
  });

  const pointerPosition: ComputedRef<string> = computed(() => {
    const videoObject: VideoState = video.state,
      videoElements: HTMLMediaElement[] = video.elements,
      isLive: boolean = videoObject.isLive;

    if (isLive) {
      return "left: 100%";
    } else if (
      !videoObject.currentTime &&
      !videoElements[0].currentTime &&
      !videoObject.duration &&
      intervalMap
    ) {
      return "left: 0;";
    } else if (videoObject.isDragging || videoObject.isResizing) {
      return `left: ${Math.min(
        100,
        (videoObject.currentTime / videoObject.duration) * 100
      )}%;`;
    } else {
      return `left: ${Math.min(
        100,
        ((videoObject.recordedPosition - videoObject.startTime) /
          (videoObject.endTime - videoObject.startTime)) *
          100
      )}%;`;
    }
  });

  const timeFrameIndicatorPosition: ComputedRef<string> = computed(() => {
    if (isSelectingTimeFrame.value && hoverTime.value) {
      const barWidth: number = _getHTMLElementWidth(indicatorRef.value);

      if (hoverX.value < 0) {
        return "left: 0;";
      } else if (hoverX.value > barWidth) {
        return "left: 100%;";
      }

      return `left: ${(hoverX.value / barWidth) * 100}%;`;
    }

    return "display: none";
  });

  const timeFramePosition: ComputedRef<Position> = computed(() => {
    const videoObject: VideoState = video.state,
      position: Position = {
        left: "",
        width: ""
      };

    if (videoObject.leftTimeFrame || hoverTime.value) {
      const barWidth: number = _getHTMLElementWidth(indicatorRef.value),
        intervalDuration: number =
          videoObject.intervalEndTime - videoObject.intervalStartTime;

      let width = 0;

      if (videoObject.leftTimeFrame && videoObject.rightTimeFrame) {
        width =
          ((videoObject.rightTimeFrame - videoObject.leftTimeFrame) /
            intervalDuration) *
          barWidth;
        position.left = `${((videoObject.leftTimeFrame +
          videoObject.recordedStartTime -
          videoObject.intervalStartTime) /
          intervalDuration) *
          barWidth}px`;
      }
      position.width = `${Math.abs(width)}px`;
      position["margin-left"] = timeFrameFlipped.value
        ? `${-Math.abs(width)}px`
        : undefined;
      position.transform = timeFrameFlipped.value ? `scaleX(-1)` : undefined;
    }

    return position;
  });

  const isSelectedTimeFrame: ComputedRef<boolean> = computed(() => {
    const videoObject: VideoState = video.state;

    return isSelectingTimeFrame.value || !!videoObject.rightTimeFrame;
  });

  const intervalMap: ComputedRef<TimeInterval[]> = computed(() => {
    const videoObject: VideoState = video.state,
      intervals: TimeInterval[] = [],
      intervalHours: number = videoObject.intervalRange / HOUR,
      intervalDuration: number =
        videoObject.intervalEndTime - videoObject.intervalStartTime;

    let largeInterval: number | null = null,
      mediumInterval: number | null = null,
      smallInterval: number | null = null,
      closestLargeIntervalTimestamp: number | null = null,
      closestMediumIntervalTimestamp: number | null = null,
      closestSmallIntervalTimestamp: number | null = null;
    if (indicatorRef.value) {
      const width: number = indicatorRef.value.offsetWidth;

      if (intervalHours <= 0.2) {
        largeInterval = SECOND * 1;
      } else if (intervalHours <= 0.5) {
        largeInterval = SECOND * 5;
      } else if (intervalHours <= 1) {
        largeInterval = SECOND * 10;
      } else if (intervalHours <= 2) {
        largeInterval = SECOND * 15;
      } else if (intervalHours <= 3) {
        largeInterval = SECOND * 30;
      } else if (intervalHours <= 4) {
        largeInterval = HOUR;
      } else if (intervalHours <= 8) {
        largeInterval = HOUR * 2;
      } else if (intervalHours <= 12) {
        largeInterval = HOUR * 4;
      } else {
        largeInterval = HOUR * 8;
      }

      mediumInterval = largeInterval / 2;
      smallInterval = mediumInterval / 2;

      (closestLargeIntervalTimestamp = largeInterval
        ? new Date(
            Math.ceil(
              (videoObject.intervalStartTime * MILLISECOND) /
                (largeInterval * MILLISECOND)
            ) *
              largeInterval *
              MILLISECOND
          ).getTime() / MILLISECOND
        : null),
        (closestMediumIntervalTimestamp = mediumInterval
          ? new Date(
              Math.ceil(
                (videoObject.intervalStartTime * MILLISECOND) /
                  (mediumInterval * MILLISECOND)
              ) *
                mediumInterval *
                MILLISECOND
            ).getTime() / MILLISECOND
          : null),
        (closestSmallIntervalTimestamp = smallInterval
          ? new Date(
              Math.ceil(
                (videoObject.intervalStartTime * MILLISECOND) /
                  (smallInterval * MILLISECOND)
              ) *
                smallInterval *
                MILLISECOND
            ).getTime() / MILLISECOND
          : null);

      if (
        closestLargeIntervalTimestamp &&
        closestMediumIntervalTimestamp &&
        closestSmallIntervalTimestamp
      ) {
        const closestTimestamp: number = Math.min.apply(Math, [
            closestLargeIntervalTimestamp,
            closestMediumIntervalTimestamp,
            closestSmallIntervalTimestamp
          ]),
          intervalTotal: number = Math.floor(intervalDuration / smallInterval),
          intervalTotalCount: number = largeInterval / smallInterval,
          intervalOffset: number =
            closestTimestamp - videoObject.intervalStartTime,
          widthPercentage: number =
            (((width - intervalTotal * INTERVAL_WIDTH) / width) * 100) /
            intervalTotal,
          offsetPercentage: number =
            (intervalOffset / smallInterval) * widthPercentage;

        let currentCount: number =
            closestLargeIntervalTimestamp - closestTimestamp
              ? intervalTotalCount -
                (closestLargeIntervalTimestamp - closestTimestamp) /
                  smallInterval
              : 4,
          intervalCount = 0;

        if (!currentCount) {
          currentCount = 1;
        }

        for (
          let time: number = closestTimestamp;
          time < videoObject.intervalEndTime;
          time += smallInterval
        ) {
          if (currentCount % intervalTotalCount === 0) {
            intervals.push({
              timestamp: time,
              displayTimestamp: _epochToHHMMSS(time),
              class: "video-control__indicator--max",
              style: `left: ${offsetPercentage +
                intervalCount * widthPercentage}%`
            });
            currentCount = 0;
          } else if (!(currentCount % 2)) {
            intervals.push({
              timestamp: time,
              displayTimestamp: "",
              class: "video-control__indicator--medium",
              style: `left: ${offsetPercentage +
                intervalCount * widthPercentage}%`
            });
          } else if (currentCount % 2) {
            intervals.push({
              timestamp: time,
              displayTimestamp: "",
              class: "video-control__indicator--small",
              style: `left: ${offsetPercentage +
                intervalCount * widthPercentage}%`
            });
          }

          intervalCount++;
          currentCount++;
        }
      }
    }

    return intervals;
  });

  const hover = (event: any): void => {
    const videoObject: VideoState = video.state,
      isLive: boolean = videoObject.isLive;

    if (isLive) {
      return;
    }

    progressHover.value = true;
    hoverX.value = event.offsetX;
  };

  const endHover = (): void => {
    const videoObject: VideoState = video.state;

    progressHover.value = false;

    if (videoObject.isDragging) {
      endDrag();
    }
  };

  const drag = (event: any): void => {
    const videoObject: VideoState = video.state;

    if (
      event.target.classList.contains("scrubber-indicator") ||
      event.target.classList.contains("time-frame-indicator") ||
      event.target.classList.contains("right-time-indicator")
    ) {
      if (isSelectingTimeFrame.value) {
        const ref = timeFrame.value
          ? timeFrame.value
          : rightTimeFrame.value
          ? rightTimeFrame.value
          : null;

        hoverX.value = ref
          ? ref.offsetLeft + event.offsetX - INTERVAL_SCRUBBER_OFFSET
          : 0;
      } else if (videoObject.isDragging) {
        dragX.value = scrubber.value
          ? scrubber.value.offsetLeft +
            event.offsetX -
            scrubber.value.offsetWidth / 2
          : event.offsetX;
      }
    } else if (
      _closest(event, ".scrubber-indicator") ||
      _closest(event, ".time-frame-indicator") ||
      _closest(event, ".right-time-indicator")
    ) {
      const width: number = event.target.offsetWidth;

      if (videoObject.isSelectingTimeFrame) {
        const ref = timeFrame.value
          ? timeFrame.value
          : rightTimeFrame.value
          ? rightTimeFrame.value
          : null;

        hoverX.value = ref
          ? ref.offsetLeft +
            event.offsetX -
            width / 2 -
            INTERVAL_SCRUBBER_OFFSET
          : 0;
      } else if (videoObject.isDragging) {
        dragX.value = scrubber.value
          ? scrubber.value.offsetLeft + event.offsetX - width / 2
          : event.offsetX;
      }
    } else {
      let offset: number = event.offsetX;

      // Check offset if contained in progress-container
      if (
        !event.target.classList.contains("time-indicator-container") &&
        _closest(event, ".time-indicator-container")
      ) {
        const targetOffset: number = event.target.offsetLeft;

        offset = targetOffset + event.offsetX - INTERVAL_SCRUBBER_OFFSET;
      }

      if (isSelectingTimeFrame.value) {
        hoverX.value = offset;
      } else if (videoObject.isDragging) {
        dragX.value = offset;
      }
    }

    if (videoObject.isSelectingTimeFrame) {
      const barWidth: number = _getHTMLElementWidth(indicatorRef.value),
        intervalDuration =
          videoObject.intervalEndTime - videoObject.intervalStartTime;

      let hoverTime: number = (hoverX.value / barWidth) * intervalDuration;

      if (videoObject.leftTimeFrame) {
        if (hoverTime < 0) {
          timeFrameFlipped.value = !timeFrameFlipped.value;
        }

        videoObject.rightTimeFrame = hoverTime += videoObject.leftTimeFrame;
      } else {
        videoObject.hoverTime = hoverTime += _intervalTimeFromStart.value;
      }
    } else if (videoObject.isDragging) {
      const barWidth: number = _getHTMLElementWidth(indicatorRef.value),
        intervalDuration =
          videoObject.intervalEndTime - videoObject.intervalStartTime;

      let seekTime: number = (dragX.value / barWidth) * intervalDuration;

      if (seekTime >= intervalDuration) {
        videoObject.currentTime = intervalDuration - 1;
      } else {
        const targetTime: number = (seekTime += _intervalTimeFromStart.value);

        if (
          videoObject.leftTimeFrame &&
          targetTime <= videoObject.leftTimeFrame
        ) {
          videoObject.currentTime = videoObject.leftTimeFrame;
        } else if (
          videoObject.rightTimeFrame &&
          targetTime >= videoObject.rightTimeFrame
        ) {
          videoObject.currentTime = videoObject.rightTimeFrame;
        } else {
          videoObject.currentTime = targetTime;
        }
      }
    }
    context.emit("updateVideo", videoObject);
  };

  const startDrag = (): void => {
    const videoObject: VideoState = video.state,
      videoElements: HTMLMediaElement[] = video.elements,
      isLive: boolean = videoObject.isLive;

    if (isLive) {
      videoObject.isLive = false;
      context.emit("updateVideo", videoObject);
    }

    // this.clearIntervals();

    videoObject.isDragging = true;
    dragX.value = scrubber.value
      ? scrubber.value.offsetLeft + INTERVAL_SCRUBBER_OFFSET
      : INTERVAL_SCRUBBER_OFFSET;

    if (videoElements[0] && videoObject.isPlaying) {
      pauseToDrag.value = true;
      videoObject.isPlaying = false;

      videoElements.forEach((video: HTMLMediaElement) => {
        video.pause();
      });
    }

    context.emit("updateVideo", videoObject);
  };

  const endDrag = (): void => {
    const videoObject: VideoState = video.state;

    if (videoObject.isDragging) {
      seek();
    }
  };

  const rateChange = (value): void => {
    const videoElements: HTMLMediaElement[] = video.elements,
      videoObject: VideoState = video.state,
      isLive: boolean = videoObject.isLive;

    if (videoElements[0] && videoObject.isPlaying) {
      console.log("CHANGING RATE");

      if (!isLive) {
        videoObject.playbackRate = value;
      }

      context.emit("updateVideo", videoObject);
    }
  };

  const seek = (event?: any): void => {
    console.log("SEEKING");
    const videoObject: VideoState = video.state,
      videoElements: HTMLMediaElement[] = video.elements,
      isLive: boolean = videoObject.isLive;

    if (isLive) {
      //     videoObject.endTime = Math.round(Date.now() / MILLISECOND);
      //     videoObject.startTime = videoObject.endTime - DEFAULT_TIME_FRAME;
      videoObject.recordedEndTime = videoObject.endTime;
      videoObject.recordedStartTime = videoObject.startTime;
      videoObject.isLive = false;
      context.emit("updateVideo", videoObject);
    }

    const intervalDuration: number =
        videoObject.intervalEndTime - videoObject.intervalStartTime,
      barWidth: number = _getHTMLElementWidth(indicatorRef.value);

    let offset: number =
        videoObject.isDragging && scrubber.value
          ? scrubber.value.offsetLeft + INTERVAL_SCRUBBER_OFFSET
          : event.offsetX,
      seekTime: number = (offset / barWidth) * intervalDuration;

    // Check offset if contained in progress-container
    if (
      !videoObject.isDragging &&
      !event.target.classList.contains("time-indicator-container") &&
      _closest(event, ".time-indicator-container")
    ) {
      const targetOffset: number = event.currentTarget.offsetLeft;

      offset = event.x - targetOffset - 120;
      seekTime = (offset / barWidth) * intervalDuration;
    }

    if (seekTime >= intervalDuration) {
      seekTime = intervalDuration - 1;
    }

    if (videoObject.isTrickmode) {
      videoObject.isTrickmode = false;
    }

    seekTime += _intervalTimeFromStart.value;

    if (isSelectingTimeFrame.value) {
      if (videoObject.leftTimeFrame) {
        const min: number = Math.min(videoObject.leftTimeFrame, seekTime),
          max: number = Math.max(videoObject.leftTimeFrame, seekTime);

        videoObject.leftTimeFrame = min;
        videoObject.rightTimeFrame = max;
        videoObject.isSelectingTimeFrame = false;
        videoObject.currentTime = videoObject.leftTimeFrame;
        videoElements.forEach((video: HTMLMediaElement) => {
          if (videoObject.leftTimeFrame) {
            video.currentTime = videoObject.leftTimeFrame;
          }
        });
        timeFrameFlipped.value = false;
      } else {
        videoObject.leftTimeFrame = seekTime;
        videoObject.rightTimeFrame = seekTime;
      }
      context.emit("updateVideo", videoObject);
      context.emit("setTimeOveride");
    } else {
      if (videoObject.leftTimeFrame && seekTime <= videoObject.leftTimeFrame) {
        videoObject.currentTime = videoObject.leftTimeFrame;
        videoElements.forEach((video: HTMLMediaElement) => {
          if (videoObject.leftTimeFrame) {
            video.currentTime = videoObject.leftTimeFrame;
          }
        });
      } else if (
        videoObject.rightTimeFrame &&
        seekTime >= videoObject.rightTimeFrame
      ) {
        videoObject.currentTime = videoObject.rightTimeFrame;
        videoElements.forEach((video: HTMLMediaElement) => {
          if (videoObject.rightTimeFrame) {
            video.currentTime = videoObject.rightTimeFrame;
          }
        });
      } else {
        videoObject.currentTime = seekTime;
        videoElements.forEach((video: HTMLMediaElement) => {
          video.currentTime = seekTime;
        });
      }

      // End drag
      videoObject.isDragging = false;
      dragX.value = 0;

      if (
        videoObject.pauseToDrag &&
        videoElements[0] &&
        !videoObject.isPlaying
      ) {
        pauseToDrag.value = false;
        videoObject.isPlaying = true;

        videoElements.forEach((video: HTMLMediaElement) => {
          video.play().catch((error: any) => {
            console.log(error);
          });
        });
      }

      const timestamp =
        (videoObject.recordedEndTime -
          (videoObject.recordedEndTime -
            videoObject.recordedStartTime -
            videoObject.currentTime)) *
        1000000;
      // timestamp = 1623383158022127 + (videoManager.state.currentTime * 1000000)
      videoObject.recordedPosition = timestamp / 1000000;
      // lastVideoTime.value = DEFAULT_AVERAGE_LIVE_TIME_AHEAD;
      context.root.$store.dispatch("switchGSTWebRTCMode", {
        command: {
          seek_command: {
            // Multiply by 1K to get nanos
            to_timestamp: (timestamp + 10000000) * 1000 // Why are we adding 10 sec?
          },
          type: "seek"
        },
        deviceId: props.video.id
      });
      context.emit("updateVideo", videoObject);
      context.emit("setTimeOveride");
    }
  };

  const resize = (event: any): void => {
    const videoObject: VideoState = video.state,
      videoElements: HTMLMediaElement[] = video.elements;

    if (
      event.target.classList.contains("mini-timeline") ||
      _closest(event, ".mini-timeline")
    ) {
      let offset: number = event.offsetX;

      // Check offset if contained in progress-container
      if (
        !event.target.classList.contains("mini-timeline-container") &&
        _closest(event, ".mini-timeline-container")
      ) {
        const targetOffset: number = event.target.offsetLeft;

        offset = targetOffset + event.offsetX;
      }

      if (videoObject.isResizing) {
        const barWidth: number = _getHTMLElementWidth(timelineBarRef.value),
          intervalDuration: number =
            videoObject.intervalEndTime - videoObject.intervalStartTime,
          seekTime: number = (offset / barWidth) * intervalDuration;

        if (
          videoObject.recordedEndTime - intervalDuration >
          videoObject.intervalStartTime + seekTime
        ) {
          const intervalStartTime: number = Math.max(
              videoObject.recordedStartTime,
              videoObject.intervalStartTime + Math.round(seekTime)
            ),
            intervalEndTime: number =
              intervalStartTime + currentIntervalRange.value;

          videoObject.intervalRange = intervalEndTime - intervalStartTime;

          if (
            _intervalTimeFromStart.value >= videoElements[0].currentTime ||
            intervalEndTime - videoObject.recordedStartTime <=
              videoElements[0].currentTime
          ) {
            videoObject.currentTime = _intervalTimeFromStart.value;
          } else {
            videoObject.currentTime = videoElements[0].currentTime;
          }

          videoObject.intervalStartTime = intervalStartTime;
          videoObject.intervalEndTime = intervalEndTime;
          context.emit("updateVideo", videoObject);
        }
      }
    }
  };

  const startResize = (): void => {
    const videoObject: VideoState = video.state,
      videoElements: HTMLMediaElement[] = video.elements,
      isLive: boolean = videoObject.isLive;

    if (isLive) {
      videoObject.isLive = false;
      videoObject.isPlaying = false;
      videoObject.currentTime =
        videoObject.endTime + videoElements[0].currentTime;
    } else {
      videoObject.currentTime = videoElements[0].currentTime;
    }

    // _clearIntervals();

    startAtBeginningInterval.value = false;

    if (!isLive) {
      pauseToDrag.value = true;

      videoElements.forEach((video: HTMLMediaElement) => {
        video.pause();
      });

      videoObject.isPlaying = false;
    }

    videoObject.isResizing = true;
    context.emit("updateVideo", videoObject);
  };

  const endResize = (): void => {
    const videoObject: VideoState = video.state,
      videoElements: HTMLMediaElement[] = video.elements;

    if (videoObject.isResizing) {
      if (pauseToDrag.value) {
        pauseToDrag.value = false;

        videoElements.forEach((video: HTMLMediaElement) => {
          video.currentTime = videoObject.currentTime;
          video.play();
        });

        videoObject.isPlaying = true;
      }

      timelineWidth.value =
        miniTimelineRef.value && miniTimelineRef.value.offsetWidth
          ? miniTimelineRef.value.offsetWidth
          : 0;
      videoObject.isResizing = false;
      context.emit("updateVideo", videoObject);
    }
  };

  const selectTimeFrame = (): void => {
    const videoObject: VideoState = video.state;

    videoObject.isSelectingTimeFrame = !isSelectedTimeFrame;
    videoObject.leftTimeFrame = undefined;
    videoObject.rightTimeFrame = undefined;

    context.emit("updateVideo", videoObject);
  };

  const noop = (): void => {
    return;
  };

  // 	_expandTimeline() {
  // 		let intervalDuration = this.currentIntervalRange,
  // 			intervalRemovalValue = Math.round( intervalDuration * TIMELINE_MODIFIER ),
  // 			intervalStartTime = Math.max( this.recordedStartTime, Math.min( ( this.intervalStartTime + this.recordedPosition ), ( this.intervalStartTime - intervalRemovalValue ) ) ),
  // 			intervalEndTime = Math.min( this.recordedEndTime, Math.max( ( this.intervalStartTime + this.recordedPosition ), ( this.intervalEndTime + intervalRemovalValue ) ) );

  // 		if ( intervalStartTime < this.intervalStartTime ) {
  // 			this.currentTime += ( this.intervalStartTime - intervalStartTime );
  // 		}

  // 		this.intervalRange = intervalEndTime - intervalStartTime;
  // 		this.setIntervalStartTime( intervalStartTime );
  // 		this.setIntervalEndTime( intervalEndTime );
  // 	},
  // 	_contractTimeline() {
  // 		let intervalDuration = this.currentIntervalRange,
  // 			intervalRemovalValue = Math.round( intervalDuration * TIMELINE_MODIFIER ),
  // 			intervalStartTime = Math.min( ( this.intervalStartTime + this.recordedPosition ), ( this.intervalStartTime + intervalRemovalValue ) ),
  // 			intervalEndTime = Math.max( ( this.intervalStartTime + this.recordedPosition ), ( this.intervalEndTime - intervalRemovalValue ) );

  // 		if ( intervalStartTime > this.intervalStartTime ) {
  // 			this.currentTime -= ( intervalStartTime - this.intervalStartTime );
  // 		}

  // 		this.intervalRange = intervalEndTime - intervalStartTime;
  // 		this.setIntervalStartTime( intervalStartTime );
  // 		this.setIntervalEndTime( intervalEndTime );
  // 	},

  //Private Functions

  const _intervalTimeFromStart: ComputedRef<number> = computed(() => {
    const videoObject: VideoState = video.state;

    return videoObject.intervalStartTime - videoObject.recordedStartTime;
  });

  const _epochToHHMMSS = (time: number): string => {
    const date = new Date(time * 1000),
      hour = date.getHours(),
      minute = date.getMinutes(),
      second = date.getSeconds();

    return (
      (hour < 10 ? "0" + hour : hour) +
      ":" +
      (minute < 10 ? "0" + minute : minute) +
      ":" +
      (second < 10 ? "0" + second : second)
    );
  };

  // IE doesn't support the .closest() so check if .closest() is supported.
  const _closest = (event: any, selector: any): any => {
    let el = event.target;

    if (el.closest) {
      return el.closest(selector);
    } else if (el.matches) {
      do {
        if (el.matches(selector)) {
          return el;
        }

        el = el.parentElement;
      } while (el !== null);

      return null;
    }
  };

  const _calculateTimelineWidth = (): void => {
    timelineWidth.value =
      miniTimelineRef.value && miniTimelineRef.value.offsetWidth
        ? miniTimelineRef.value.offsetWidth
        : 0;
  };

  const _getHTMLElementWidth = (element: HTMLElement | undefined) => {
    return element ? element.offsetWidth || element.clientWidth : 0;
  };

  return {
    seek,
    resize,
    startResize,
    endResize,
    drag,
    startDrag,
    endDrag,
    hover,
    endHover,
    isResizing,
    isDragging,
    miniTimelinePosition,
    scrubberPosition,
    pointerPosition,
    intervalMap,
    selectTimeFrame,
    isSelectingTimeFrame,
    isSelectedTimeFrame,
    timeFrameIndicatorPosition,
    timeFramePosition,
    noop,
    indicatorRef,
    miniTimelineRef,
    timelineWidth,
    timelineBarRef,
    scrubber,
    rateValueRef,
    timeFrame,
    rightTimeFrame,
    rateChange
  };
}

function _debounce(time: number, callback: Function) {
  let interval: any;

  return (...args: any) => {
    clearTimeout(interval);
    interval = setTimeout(() => {
      interval = null;
      callback(...args);
    }, time);
  };
}
