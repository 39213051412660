





















































































































































import { ref, computed, onUnmounted, onMounted } from "@vue/composition-api";
import WebRTCVideoPlayer from "../GSTWebRTCVideoPlayer/WebRTCVideoPlayer.vue";
import Vue from "vue";
import VueNativeSock from "vue-native-websocket";
import { passToStoreHandler } from "../../store/socketManager";

export default {
  components: {
    WebRTCVideoPlayer
  },
  setup(props: any, context: any) {
    const janusAlertManager: any = usejanusAlertManager(context);

    const isShowingVideo = ref<boolean>(false),
      isManuallyInputStream = ref<boolean>(false),
      deviceID = ref<string>(""),
      deviceName = ref<string>(""),
      vm = ref<any>(null),
      chosenVideoList = ref<any[]>([]),
      newCameraStreams = ref<any[]>([]),
      isAddingNewCamera = ref<boolean>(false),
      // videoSrc = ref(""),
      keepAliveInterval = ref<number | null>(null),
      // videoKey = ref<number>(0),
      streamState = ref<string>("default"),
      // janusReconnectionCount = computed(() => {
      //   return context.root.$store.getters.reconnectionCount;
      // }),
      isConnected = computed(async () => {
        if (!context.root.$store.getters.isConnected) {
          await connectToWebsocketProxy();
          createGSTSession();
        }

        return context.root.$store.getters.isConnected;
      }),
      // janusSession = computed(() => {
      //   return context.root.$store.getters.janusSessionId;
      // }),
      // janusCreatingSession = computed(
      //   () => context.root.$store.getters.janusCreatingSession
      // ),
      // janusWebRTCAnswer = computed(() => {
      //   if (janusStartedStream.value && !janusStoppedStream.value) {
      //     loadingWebRTC.value = false;
      //   }

      //   return context.root.$store.getters.janusWebRTCAnswer;
      // }),
      // janusStartedStream = computed(() => {
      //   return context.root.$store.getters.janusStartedStream;
      // }),
      // janusStartingStream = computed(() => {
      //   return context.root.$store.getters.janusStartingStream;
      // }),
      // janusStoppedStream = computed(() => {
      //   return context.root.$store.getters.janusStoppedStream;
      // }),
      computedVideoList = computed(() => {
        context.root.$store.getters.gstConfigList.forEach((video: any) => {
          video.watching = false;

          chosenVideoList.value.forEach(chosenVideo => {
            if (video.device_id === chosenVideo.camera_label) {
              video.watching = true;
            }
          });
        });

        return context.root.$store.getters.gstConfigList.sort(
          (a: any, b: any) => {
            return a.camera_label > b.camera_label ? 1 : -1;
          }
        );
      });

    const closeVideo = (): void => {
      chosenVideoList.value = [];
      isShowingVideo.value = false;
      // context.root.$store.dispatch("clearStreams");
      context.root.$store.dispatch("stopGSTWebRTCSystem");
    };

    const closeStreamModal = (): void => {
      isAddingNewCamera.value = false;
      isManuallyInputStream.value = false;
      newCameraStreams.value = [];
    };

    const saveCameraStreams = (): void => {
      const manuallyAddedStreams = newCameraStreams.value.filter(video => {
        return video.local;
      });

      context.root.$store.dispatch(
        "setGSTConfigList",
        JSON.parse(JSON.stringify(newCameraStreams.value))
      );
      localStorage.setItem(
        "gstConfigList",
        JSON.stringify(manuallyAddedStreams)
      );
      isAddingNewCamera.value = false;
      newCameraStreams.value = [];
    };

    // const switchStream = (id: number) => {
    //   console.log("SWITCHING");
    //   // loadingWebRTC.value = true;
    //   // context.root.$store.dispatch("switchJanusStream", id);
    // };

    const createGSTSession = () => {
      // if (!janusSession.value && !janusCreatingSession.value) {
      console.log("CREATING");
      context.root.$store.dispatch("startGSTConnection");
      // }
    };

    // const destroyJanusSession = () => {
    //   console.log("DESTROYING");
    //   context.root.$store.dispatch("destroyJanusSession");
    //   _clearIntervals();
    // };

    // const destroyStreamingPlugin = () => {
    //   console.log("DESTROYING STREAMING PLUGIN");
    //   context.root.$store.dispatch("destroyStreamingPlugin");
    // };

    const _keepAliveInterval = (): void => {
      context.root.$store.dispatch("heartbeatWebSocketProxy");
    };

    const _clearIntervals = (): void => {
      if (keepAliveInterval.value) {
        clearInterval(keepAliveInterval.value);
        keepAliveInterval.value = null;
      }
    };

    const openVideo = (video: any): void => {
      if (streamState.value === "default") {
        if (video.ip_address) {
          connectToLocalWebsocket(video);
          chosenVideoList.value.push(video);
          isShowingVideo.value = true;
          createGSTSession();
        } else {
          connectToWebsocketProxy();
          chosenVideoList.value.push(video);
          isShowingVideo.value = true;
          createGSTSession();
        }
        // getWebRTCOffer(video.id);
      } else if (streamState.value === "add") {
        chosenVideoList.value.push(video);
        isShowingVideo.value = true;
        streamState.value = "default";
      }
    };

    const addNewCameraStream = (): void => {
      isAddingNewCamera.value = true;
      newCameraStreams.value = JSON.parse(
        JSON.stringify(context.root.$store.getters.gstConfigList)
      );
    };

    const addCameraStream = (): void => {
      streamState.value = "add";
      isShowingVideo.value = false;
    };

    const switchCameraStream = (): void => {
      isShowingVideo.value = false;
    };

    const removeCameraStream = (index: number): void => {
      const stream = chosenVideoList.value[index];

      chosenVideoList.value.splice(index, 1);
      context.root.$store.dispatch("unmountJanusStream", stream.id);
    };

    const removeNewCameraStream = (index: number): void => {
      newCameraStreams.value.splice(index, 1);
    };

    const addNewManualCameraStream = (): any => {
      newCameraStreams.value.push({
        // camera_id: deviceID.value,
        device_id: deviceID.value,
        display_name: deviceName.value,
        local: true
      });
      isManuallyInputStream.value = false;
      deviceName.value = "";
      deviceID.value = "";

      return false;
    };

    const connectToLocalWebsocket = (camera): any => {
      const socketServerURL = `${"ws://"}${camera.ip_address}:${"8980"}`;

      if (!vm.value) {
        vm.value = new Vue();

        Vue.use(VueNativeSock, socketServerURL, {
          connectManually: true,
          format: "json"
        });

        vm.value.$connect();
      } else {
        vm.value.$connect(socketServerURL, {
          connectManually: true,
          format: "json"
        });
      }
    };

    const connectToWebsocketProxy = async (): Promise<any> => {
      const socketServerURL = `${process.env.VUE_APP_SIGNALSERVERPREFIX}${process.env.VUE_APP_CURATORURL}proxy-ws/${context.root.$store.getters["authStore/getTenantID"]}`;
      console.log(socketServerURL);

      if (!vm.value) {
        _clearIntervals();
        vm.value = new Vue();

        Vue.use(VueNativeSock, socketServerURL, {
          // connectManually: true,
          format: "json",
          store: context.root.$store,
          passToStoreHandler,
          reconnection: true,
          reconnectionAttempts: 10,
          reconnectionDelay: 5000
        });

        // vm.value.$connect();

        keepAliveInterval.value = setInterval(_keepAliveInterval, 10000);
      } else {
        // _clearIntervals();
        /*
        vm.value.$connect(socketServerURL, {
          // connectManually: true,
          format: "json",
          store: context.root.$store,
          passToStoreHandler,
          reconnection: true,
          reconnectionAttempts: 10,
          reconnectionDelay: 5000
        });
        keepAliveInterval.value = setInterval(_keepAliveInterval, 10000);
         */
      }
    };

    const disconnectFromWebSocket = (): any => {
      vm.value.$socket.close(1000);
    };

    const disconnectFromWebSocketProxy = (): any => {
      context.root.$store.dispatch("disconnectFromWebSocketProxy");
    };

    // onMounted(() => {
    //   context.root.$store.dispatch("connectToWebsocketProxy");
    // });

    onUnmounted(() => {
      // if (context.root.$store.getters.janusStartedStream) {
      // unmountJanusStream();
      // }
      // destroyJanusSession();
      // destroyStreamingPlugin();
      disconnectFromWebSocket();
      // disconnectFromWebSocketProxy();
      context.root.$store.dispatch("stopGSTWebRTCSystem");
      _clearIntervals();
    });

    return {
      // videoSrc,
      isConnected,
      // janusWebRTCAnswer,
      // janusStartingStream,
      // janusStartedStream,
      // janusStoppedStream,
      computedVideoList,
      // getWebRTCOffer,
      // unmountJanusStream,
      // loadingWebRTC,
      saveCameraStreams,
      deviceID,
      deviceName,
      isManuallyInputStream,
      newCameraStreams,
      removeNewCameraStream,
      addNewManualCameraStream,
      openVideo,
      isAddingNewCamera,
      addNewCameraStream,
      closeStreamModal,
      addCameraStream,
      switchCameraStream,
      removeCameraStream,
      chosenVideoList,
      closeVideo,
      isShowingVideo,
      streamState,
      ...janusAlertManager
    };
  }
};

function usejanusAlertManager(context: any): any {
  const errorMessage = computed(() => {
    return context.root.$store.getters.errorMessage;
  });

  const showError = computed(() => {
    return context.root.$store.getters.showError;
  });

  const dismissError = () => {
    context.root.$store.dispatch("dismissErrorMessage");
  };

  return {
    showError,
    errorMessage,
    dismissError
  };
}
