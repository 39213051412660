import { render, staticRenderFns } from "./WebRTCPlayer.vue?vue&type=template&id=9fc0b8f0&"
import script from "./WebRTCPlayer.vue?vue&type=script&lang=ts&"
export * from "./WebRTCPlayer.vue?vue&type=script&lang=ts&"
import style0 from "./WebRTCPlayer.scss?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../edge-video-system-py/html/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports