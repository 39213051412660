import axios from "axios";

function underscoreToCamelCase(data) {
    if (typeof data != "object") return data;
  
    for (const oldName in data) {
      // Camel to underscore
      const newName = oldName.replace(/([-_][a-z])/gi, str => {
        return str
          .toUpperCase()
          .replace("-", "")
          .replace("_", "");
      });
  
      // Only process if names are different
      if (newName != oldName) {
        // Check for the old property name to avoid a ReferenceError in strict mode.
        if (Object.prototype.hasOwnProperty.call(data, oldName)) {
          data[newName] = data[oldName];
          delete data[oldName];
        }
      }
  
      // Recursion
      if (typeof data[newName] == "object") {
        data[newName] = underscoreToCamelCase(data[newName]);
      }
    }
    return data;
}

function camelCaseKeysToUnderscore(obj) {
    if (typeof obj != "object") return obj;
  
    for (const oldName in obj) {
      // Camel to underscore
      const newName = oldName.replace(/([A-Z])/g, function($1) {
        return "_" + $1.toLowerCase();
      });
  
      // Only process if names are different
      if (newName != oldName) {
        // Check for the old property name to avoid a ReferenceError in strict mode.
        if (Object.prototype.hasOwnProperty.call(obj, oldName)) {
          obj[newName] = obj[oldName];
          delete obj[oldName];
        }
      }
  
      // Recursion
      if (typeof obj[newName] == "object") {
        obj[newName] = camelCaseKeysToUnderscore(obj[newName]);
      }
    }
    return obj;
}

const state = () => ({
    authenticated: false,
    authError: '',
    tenantId: '',
    clientId: -1,
    token: {
        accessToken: '',
        expiresIn: 0,
        idToken: '',
        refreshToken: '',
        scope: '',
        tokenType: ''
    }
  });

  const mutations = {
      setAuthError(state, data) {
          state.authError = data;
      },
      setAuthenticated(state, data) {
          state.authenticated = data;
      },
      setAccessToken(state, data) {
          state.token.accessToken = data;
      },
      setRefreshToken(state, data) {
          state.token.refreshToken = data;
      },
      setExpiresIn(state, data) {
          state.token.expiresIn = data;
      },
      setToken(state, data) {
          state.token = data;
      },
      setTenantID(state, data) {
          state.tenantId = data;
      },
      resetToken(state) {
            state.token = {
                accessToken: '',
                expiresIn: 0,
                idToken: '',
                refreshToken: '',
                scope: '',
                tokenType: ''
            }
      },
      CREATE_CLIENT_ID(state) {
        state.clientId = Math.random()
          .toString(36)
          .replace(/[^a-z]+/g, "");
      },
  };

  const actions = {
      async login({ commit, dispatch } , payload) {
        const tenantId = payload.tenantId,
            response = await axios
            .post(`https://${process.env.VUE_APP_AUTHURL}/v1/native/login`,
                camelCaseKeysToUnderscore(payload),{ 
                    headers: {
                        "Content-Type": "application/json",
                        "Access-Control-Allow-Origin": "*"
                    }
                })
            .catch((err) => {
                console.log(err);
            });
        
        if (response && response.data) {
            const token = underscoreToCamelCase(response.data);

            commit( "setAuthenticated", true)
            commit( "setTenantID", tenantId)
            commit( "setAccessToken", token.accessToken)
            commit( "setRefreshToken", token.refreshToken)
            commit( "setExpiresIn", token.expiresIn)
            commit( "setAuthError", "")
            commit( "CREATE_CLIENT_ID")

            localStorage.setItem('token', JSON.stringify(token))
            localStorage.setItem('tenantId', tenantId)
        } else {
            dispatch("resetAuthState")
            commit( "setAuthError", "Login Failed")
        }
      },
      async refreshToken({ commit, dispatch } , payload) {
        const tenantId = payload.tenantId,
            response = await axios
            .post(`https://${process.env.VUE_APP_AUTHURL}/v1/native/refresh`,
                camelCaseKeysToUnderscore(payload),
                { 
                    headers: {
                        "Content-Type": "application/json",
                        "Access-Control-Allow-Origin": "*"
                    }
                })
            .catch((err) => {
                console.log(err);
            });
        
        if (response && response.data) {
            const token = underscoreToCamelCase(response.data);

            commit( "setAuthenticated", true)
            commit( "setTenantID", tenantId)
            commit( "setAccessToken", token.accessToken)
            commit( "setRefreshToken", token.refreshToken)
            commit( "setExpiresIn", token.expiresIn)
            commit( "setAuthError", "")
            commit( "CREATE_CLIENT_ID" )

            localStorage.setItem('token', JSON.stringify(token))
            localStorage.setItem('tenantId', tenantId)
        } else {
            dispatch("resetAuthState")
            commit( "setAuthError", "Login Failed")
        }
      },
      async checkAuthentication({ dispatch }, refreshData) {
        const localStoreToken =  JSON.parse(localStorage.getItem('token')),
            tenantId = localStorage.getItem('tenantId');

        if ( refreshData.refreshToken && refreshData.tenantId ) {
          await dispatch('refreshToken', refreshData)
        }
        else if (localStoreToken && tenantId) {
          await dispatch('refreshToken', {
            refreshToken: localStoreToken.refreshToken,
            tenantId: tenantId
          })
        }
        else {
            dispatch("resetAuthState")
        }
      },
      resetAuthState({commit}) {
        commit('resetToken')
        commit('setAuthenticated', false)
        commit( "setTenantID", '')
      }
  };
   
  const getters = {
    isAuthenticated(state) {
      return state.authenticated;
    },
    getAccessToken(state) {
        return state.token.accessToken;
    },
    getTenantID(state) {
        return state.tenantId;
    },
    getClientID(state) {
        return state.clientId;
    }
  };
   
   
  export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};