<template>
    <v-form
        ref="form"
        lazy-validation
    >
        <v-text-field
        v-model="username"
        label="Email"
        hide-details="auto"
        ></v-text-field>
        <v-text-field
        v-model="password"
        label="Password"
        hide-details="auto"
        :type="'password'"
        ></v-text-field>
        <v-text-field
        v-model="tenantId"
        label="Tenant ID"
        hide-details="auto"
        ></v-text-field>
        <v-btn outlined @click="authenticate" style="margin-top: 16px;">Submit</v-btn>
    </v-form>
</template>

<script>
// @ is an alias to /src
import { mapActions, mapGetters } from "vuex";

export default {
  name: 'LoginForm',
  data() {
    return {
      username: '',
      password: '',
      tenantId: 'shop_poc'
    }
  },
  mounted() {
  },
  computed: {
    ...mapGetters("authStore", {
      isAuthenticated: "isAuthenticated",
    }),
  },
  methods: {
    ...mapActions("authStore", {
      login: "login",
    }),
    async authenticate() {
      const payload = {
          username: this.username,
          password: this.password,
          tenantId: this.tenantId
        };

      await this.login(payload)
      if(this.isAuthenticated){
        this.$router.push("/");
      }else{
        alert("failed")
      }

    }
  }
}
</script>