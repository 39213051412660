import Vue from "vue";

declare let MediaRecorder: any;

export default {
  state: {
    socket: {
      isConnected: false,
      keepAliveInterval: null,
      message: "",
      reconnectError: false,
      iceServers: [],
      janus: {
        sessionId: null,
        creatingSession: false,
        attachingSession: false,
        transactionId: null,
        transactionType: null,
        configList: [],
        streams: [],
        reconnectCount: 0,
        transactionQueue: []
      },
      gst: {
        streams: [],
        vodTimeSent: false,
        configList: JSON.parse(localStorage.getItem("gstConfigList")) || []
      }
    }
  },
  mutations: {
    SOCKET_ONOPEN(state, event) {
      Vue.prototype.$socket = event.currentTarget;
      state.socket.isConnected = true;
    },
    SOCKET_ONCLOSE(state) {
      state.socket.isConnected = false;
    },
    SOCKET_ONMESSAGE(state, message) {
      state.socket.message = message;
    },
    SOCKET_RECONNECT(state, count) {
      console.info(state, count);
    },
    SOCKET_RECONNECT_ERROR(state) {
      state.socket.reconnectError = true;
    }
  },
  actions: {
    sendMessage: function(context, message) {
      const token = context.getters["authStore/getAccessToken"],
        clientId = context.getters["authStore/getClientID"],
        data = {
          token,
          command: {
            command: message.command,
            clientId: clientId,
            deviceId: message.deviceId
          }
        },
        msg = camelCaseKeysToUnderscore(data);

      // console.log("Sending Message: " + JSON.stringify(msg));
      Vue.prototype.$socket.send(JSON.stringify(msg));
    },
    setError: function(context, error) {
      context.dispatch("setErrorMessage", error);
      // context.dispatch("unmountJanusStream");
    },
    keepAliveInterval: function(context) {
      context.dispatch("heartbeatWebSocketProxy");
    },
    clearIntervals: function(context) {
      if (context.state.socket.keepAliveInterval) {
        clearInterval(context.state.socket.keepAliveInterval);
        context.state.socket.keepAliveInterval = null;
      }
    },
    heartbeatWebSocketProxy: function(context) {
      console.log("KEEPING ALIVE");
      Vue.prototype.$socket.send(null);
    },
    startGSTConnection: function(context) {
      context.state.socket.gst.streams.push({
        id: null,
        info: null,
        jsep: {
          offer: null,
          answer: null
        },
        started: false,
        starting: false,
        stopped: true,
        sentAnswer: false,
        pc: null,
        candidates: [],
        chunk: null,
        binary: ""
      });

      // context.dispatch("getGSTWebRTCOffer")
    },
    getGSTWebRTCOffer: function(context, data) {
      const configuration = {
          iceServers: context.state.socket.iceServers,
          // iceTransportPolicy: "relay",
          bundlePolicy: "max-bundle"
        },
        stream = context.state.socket.gst.streams.find(stream => !stream.id); // AGuereca: This find is a "strange" way to look for a null id

      if (stream && data.id) {
        // ToDo: How do we properly create an RTCBundlePolicy object?!
        // @ts-ignore
        const pc = new RTCPeerConnection(configuration);
        const start_command = {
          command: {
            type: "start"
          },
          deviceId: data.id
        };

        stream.id = data.id;
        stream.video = data.video;

        pc.onnegotiationneeded = (event: any) => {
          console.log("NEGOTIATION NEEDED", event);
        };

        // pc.addEventListener("datachannel", event => {
        //   console.log("DATA CHANNEL");
        //   const dataChannel = event.channel;
        // });

        pc.ontrack = (event: any) => {
          const [ra, rv] = pc.getReceivers();
          // console.log(rv, ra);
          console.log("adding track", stream.video, event.streams[0]);
          stream.rv = rv;
          stream.ra = ra;
          stream.track = event.streams[0];
          stream.video.srcObject = event.streams[0];
          // createVideoCache(stream, data);
        };

        pc.onicecandidate = (event: any) => {
          console.log("GETTING ICE CANDIDATE", event);
          if (event.candidate && !event.candidate.completed) {
            // TODO: Why the check for "completed"
            const candidate = event.candidate;

            stream.candidates.push(candidate);
            context.dispatch("trickleGSTIceCandidates", {
              candidate,
              stream,
              id: stream.id
            });
          }
        };

        pc.oniceconnectionstatechange = () => {
          console.log("ICE CONNECTION STATE CHANGED");
        };

        stream.pc = pc;

        // NOTE: This is a hack!!!
        //       The problem that this is fixing is that watching is true for all cameras and not just the selected
        //       It should be figured out a way to update configList before reaching here, but this works for now.
        //       The limitation is that only one camera is marked as watching and not all selected (multi-display)
        context.state.socket.gst.configList.forEach((video: any) => {
          video.watching = video.camera_label == data.id;
        });

        // Note: There is no need to send start message, all what was needed was to setup the PeerConnection
        context.dispatch("sendMessage", start_command);
      }
    },
    stopGSTWebRTCSystem: function(context) {
      context.state.socket.gst.streams.forEach(stream => {
        const stop = {
          command: {
            type: "stop"
          },
          deviceId: stream.id
        };

        context.dispatch("sendMessage", stop);
      });
      context.state.socket.gst.streams = [];
    },
    trickleGSTIceCandidates: function(context, data) {
      const candidate = {
          candidate: data.candidate.candidate,
          sdp_mline_index: data.candidate.sdpMLineIndex,
          sdp_mid: data.candidate.sdpMid
        },
        trickle = {
          command: {
            candidate: candidate,
            type: "ice"
          },
          deviceId: data.id
        };

      context.dispatch("sendMessage", trickle);

      data.stream.candidates = [];
    },
    setGSTJSEPOffer: function(context, data) {
      // AGuereca: This find is disabled because there is no "deviceId" on
      //           received data, and streams seems to have only one
      //           element anyway.
      // const stream = context.state.socket.gst.streams.find(
      //   stream => data.deviceId === stream.deviceId
      // );

      // HACK: To fix previous find. See note.
      const stream = context.state.socket.gst.streams[0];

      if (stream) {
        stream.jsep.offer = data;

        stream!
          .pc!.setRemoteDescription(new RTCSessionDescription(data))
          .then(() => {
            stream.pc.createAnswer().then(answer => {
              stream.pc
                .setLocalDescription(new RTCSessionDescription(answer))
                .then(() => {
                  console.log("Local Description set");
                });

              // console.log("answer created", answer);
              stream.jsep.answer = JSON.parse(JSON.stringify(answer));
              context.dispatch("sendGSTWebRTCAnswer", stream);
            });
          });
      }
    },
    sendGSTWebRTCAnswer: function(context, stream) {
      const sdpCommand = {
          command: {
            sdp: stream.jsep.answer,
            type: "sdp"
          },
          deviceId: stream.id
        },
        liveCommand = {
          command: {
            type: "live"
          },
          deviceId: stream.id
        };

      context.dispatch("sendMessage", sdpCommand);

      stream.sentAnswer = true;
      // Note: This starts livestreaming right away, but it doesn't have to be that way,
      // it could be vod first by sending a "seek" command.
      context.dispatch("sendMessage", liveCommand);
    },
    switchGSTWebRTCMode: function(context, data) {
      const gstConfigList = context.getters.gstConfigList,
        playingCameras = gstConfigList.filter(camera => camera.watching);

      playingCameras.forEach(camera => {
        data.deviceId = camera.camera_label;

        context.dispatch("sendMessage", data);
      });
    },
    setGSTWebRTCVodTimeSent: function(context, timeSent) {
      context.state.socket.gst.vodTimeSent = timeSent;
    },
    addIceCandidate: function(context, data) {
      console.log("ADD ICE CANDIDATE ", data, context.state.socket.gst.streams);
      const stream = context.state.socket.gst.streams.find(
        stream => stream.id === data.deviceId
      );

      if (stream && stream.pc) {
        stream.pc.addIceCandidate(data.candidate);
      }
    },
    setIceConfig: function(context, data) {
      context.state.socket.iceServers = data;
    },
    resetGSTState: function(context) {
      context.dispatch("stopGSTWebRTCSystem");
      context.dispatch("authStore/resetAuthState");
    }
  },
  getters: {
    isConnected(state) {
      return state.socket.isConnected;
    },
    peerConnection(state) {
      return id => {
        const stream = state.socket.janus.streams.find(
          stream => stream.id === id
        );

        return stream ? stream.pc : null;
      };
    },
    iceCandidates(state) {
      return id => {
        const stream = state.socket.janus.streams.find(
          stream => stream.id === id
        );

        return stream ? stream.candidates : [];
      };
    },
    webRTCAnswerSent(state) {
      return id => {
        const stream = state.socket.janus.streams.find(
          stream => stream.id === id
        );

        return stream ? stream.sentAnswer : false;
      };
    },
    reconnectionCount(state) {
      return state.socket.janus.reconnectCount;
    },
    webrtcPlayerBlobData(state) {
      return id => {
        const stream = state.socket.janus.streams.find(
          stream => stream.id === id
        );

        return stream ? stream.binary : false;
      };
    },
    gstStartedStream(state) {
      return id => {
        const stream = state.socket.janus.streams.find(
          stream => stream.id === id
        );

        return stream ? stream.started : false;
      };
    },
    gstConfigList(state) {
      return state.socket.gst.configList;
    },
    gstWebRTCVodTimeSent(state) {
      return state.socket.gst.vodTimeSent;
    }
  },
  modules: {}
};

// function createVideoCache(stream: any, data: any = null) {
//   const ms = new MediaSource(),
//     mimeType = getSupportedMimeTypes(stream.info?.video, stream.info?.audio),
//     chunkVideo = data.chunkVideo,
//     chunks: Array<ArrayBuffer> = [];

//   let sourceBuffer: any | null = null,
//     recorder: any | null = null;

//   ms.addEventListener("sourceopen", () => {
//     sourceBuffer = ms.addSourceBuffer(mimeType[0]);

//     sourceBuffer.addEventListener(
//       "updateend",
//       function() {
//         if (chunks.length) {
//           const chunk: ArrayBuffer | undefined = chunks.shift();

//           sourceBuffer.appendBuffer(new Uint8Array(chunk as ArrayBuffer));
//         }
//       },
//       false
//     );
//   });
//   stream.chunkSRC = window.URL.createObjectURL(ms);

//   chunkVideo.src = stream.chunkSRC;

//   recorder = new MediaRecorder(stream.track, { mimeType: mimeType[0] });
//   recorder.start(500);

//   recorder.ondataavailable = function(e) {
//     console.log("DATA AVAILABLE");
//     stream.chunk = e.data;
//     console.log(stream.rv, stream.ra);

//     const vParams =
//         stream.rv !== undefined ? stream.rv.getSynchronizationSources() : null,
//       aParams =
//         stream.ra !== undefined ? stream.ra.getSynchronizationSources() : null;

//     console.log(vParams, aParams);

//     const reader = new FileReader();
//     reader.readAsArrayBuffer(e.data);
//     reader.onloadend = () => {
//       const enc = new TextDecoder("utf-8");

//       if (sourceBuffer) {
//         chunks.push(reader.result as ArrayBuffer);
//         if (stream.binary === "") {
//           sourceBuffer.appendBuffer(
//             new Uint8Array(reader.result as ArrayBuffer)
//           );
//           chunks.shift();
//         } else if (!sourceBuffer.updating) {
//           const chunk = chunks.shift();

//           sourceBuffer.appendBuffer(new Uint8Array(chunk as ArrayBuffer));
//         }
//       }

//       stream.binary = enc.decode(new Int8Array(reader.result as ArrayBuffer));
//     };
//   };

//   stream.recorder = recorder;
//   stream.chunkVideo = chunkVideo;
//   stream.ms = ms;
// }

// function getSupportedMimeTypes(video = null, audio = null) {
//   const VIDEO_TYPES = ["webm", "ogg", "mp4", "x-matroska"],
//     VIDEO_CODECS = [
//       "vp9",
//       "vp9.0",
//       "vp8",
//       "vp8.0",
//       "avc1",
//       "av1",
//       "h265",
//       "h.265",
//       "h264",
//       "h.264"
//     ],
//     AUDIO_CODECS = ["opus"];

//   const supportedTypes: Array<string> = [];
//   VIDEO_TYPES.forEach((videoType: string) => {
//     const type = `video/${videoType}`;
//     VIDEO_CODECS.forEach((vc: string) => {
//       AUDIO_CODECS.forEach((ac: string) => {
//         const variations: Array<string> = [
//           `${type};codecs=${video ? vc : ""}${
//             audio ? (audio && video ? ", " + ac : "" + ac) : ""
//           }`,
//           `${type};codecs:${video ? vc : ""}${
//             audio ? (audio && video ? ", " + ac : "" + ac) : ""
//           }`,
//           `${type};codecs=${video ? vc.toUpperCase() : ""}${
//             audio
//               ? audio && video
//                 ? ", " + ac.toUpperCase()
//                 : "" + ac.toUpperCase()
//               : ""
//           }`,
//           `${type};codecs:${video ? vc.toUpperCase() : ""}${
//             audio
//               ? audio && video
//                 ? ", " + ac.toUpperCase()
//                 : "" + ac.toUpperCase()
//               : ""
//           }`,
//           `${type}`
//         ];
//         variations.forEach((variation: string) => {
//           if (
//             MediaRecorder.isTypeSupported(variation) &&
//             MediaSource.isTypeSupported(variation)
//           )
//             supportedTypes.push(variation);
//         });
//       });
//     });
//   });
//   return supportedTypes;
// }

function camelCaseKeysToUnderscore(obj) {
  if (typeof obj != "object") return obj;

  for (const oldName in obj) {
    // Camel to underscore
    const newName = oldName.replace(/([A-Z])/g, function($1) {
      return "_" + $1.toLowerCase();
    });

    // Only process if names are different
    if (newName != oldName) {
      // Check for the old property name to avoid a ReferenceError in strict mode.
      if (Object.prototype.hasOwnProperty.call(obj, oldName)) {
        obj[newName] = obj[oldName];
        delete obj[oldName];
      }
    }

    // Recursion
    if (typeof obj[newName] == "object") {
      obj[newName] = camelCaseKeysToUnderscore(obj[newName]);
    }
  }
  return obj;
}
