import Vue from "vue";

export default {
  state: {
    errorMessage: "",
    showError: false
  },
  mutations: {
    SET_ERROR_MESSAGE(state, errorLog) {
      state.errorMessage = errorLog;
    },
    SHOW_ERROR_MESSAGE(state, show) {
      state.showError = show;
    }
  },
  actions: {
    setErrorMessage: function(context, message) {
      console.log("SETTING ERROR", message);
      context.commit("SET_ERROR_MESSAGE", message);
      context.commit("SHOW_ERROR_MESSAGE", true);
      setTimeout(function() {
        context.dispatch("dismissErrorMessage");
      }, 10000);
    },
    dismissErrorMessage: function(context) {
      context.commit("SET_ERROR_MESSAGE", "");
      context.commit("SHOW_ERROR_MESSAGE", false);
    }
  },
  getters: {
    showError(state) {
      return state.showError;
    },
    errorMessage(state) {
      return state.errorMessage;
    }
  }
};
