















































































import {
  computed,
  ref,
  reactive,
  ComputedRef,
  onUnmounted
} from "@vue/composition-api";
import VideoControlsComponent from "../../components/VideoControlsComponent/VideoControlsComponent.vue";
import WebRTCPlayer from "../../components/GSTWebRTCPlayer/WebRTCPlayer.vue";
import {
  UseMultiVideoManager,
  VideoManager,
  VideoState
} from "../../types/VideoPlayerInterface";
import { VBtn } from "vuetify/lib";

const DEFAULT_INTERVAL_RANGE = 300,
  DEFAULT_RANGE = 600,
  DEFAULT_AVERAGE_LIVE_TIME_AHEAD = 0,
  MILLISECOND = 1000;

export default {
  props: {
    videos: Array,
    onlyVideo: {
      type: Boolean,
      required: false,
      default: false
    },
    showControls: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  setup(props: any, context: any) {
    const multiVideoManager: UseMultiVideoManager = useMultiVideoManager(
      props,
      context
    );
    const videoManager: any = useVideoManager(context);

    // const webrtcPlayerBlobData = computed(() => {
    //   const video =
    //     props.videos && props.videos.length > 0 ? props.videos[0] : null;

    //   return video
    //     ? context.root.$store.getters.webrtcPlayerBlobData(video.binary)
    //     : "";
    // });

    // const blobDataSize = computed(() => {
    //   const objectList: Array<any> = [];
    //   const stack: Array<string> = [webrtcPlayerBlobData.value];
    //   let bytes = 0;

    //   while (stack.length) {
    //     const value: any = stack.pop();

    //     if (typeof value === "boolean") {
    //       bytes += 4;
    //     } else if (typeof value === "string") {
    //       bytes += value.length * 2;
    //     } else if (typeof value === "number") {
    //       bytes += 8;
    //     } else if (
    //       typeof value === "object" &&
    //       objectList.indexOf(value) === -1
    //     ) {
    //       objectList.push(value);

    //       for (const i in value) {
    //         stack.push(value[i]);
    //       }
    //     }
    //   }
    //   return bytes;
    // });

    const videoLength = computed(() => {
      return props.videos ? props.videos.length : 0;
    });

    window.onbeforeunload = () => {
      // Cancel the event
      context.root.$store.dispatch("stopGSTWebRTCSystem");

      window.onbeforeunload = null;

      return;
    };

    onUnmounted(() => {
      console.log("UNMOUNTING");

      context.root.$store.dispatch("stopGSTWebRTCSystem");
      clearInterval(videoManager.timeInterval.value);
      videoManager.timeInterval.value = null;
      window.onbeforeunload = null;
    });

    return {
      ...multiVideoManager,
      ...videoManager,
      // webrtcPlayerBlobData,
      // blobDataSize,
      videoLength
    };
  },
  components: {
    VideoControlsComponent,
    WebRTCPlayer,
    VBtn
  }
};

function useMultiVideoManager(props: any, context: any): UseMultiVideoManager {
  const isEditing = ref<boolean>(false);

  const addCameraStream = (): void => {
    context.emit("addCameraStream");
    // context.root.$store.dispatch("attachJanusSession");
  };

  const switchCameraStream = (): void => {
    context.emit("switchCameraStream");
  };

  const editCameraStreams = (): void => {
    isEditing.value = !isEditing.value;
  };

  const removeCameraStream = (index: number): void => {
    context.emit("removeCameraStream", index);
  };

  return {
    addCameraStream,
    editCameraStreams,
    switchCameraStream,
    removeCameraStream,
    isEditing
  };
}

function useVideoManager(context: any): any {
  const videoState = reactive<VideoState>({
      startTime: 0,
      endTime: DEFAULT_INTERVAL_RANGE,
      duration: 0,
      currentTime: 0,
      recordedPosition: 0,
      recordedEndTime: 0,
      recordedStartTime: 0,
      intervalRange: 0,
      intervalEndTime: 0,
      intervalStartTime: 0,
      isPlaying: true,
      isResizing: false,
      isDragging: false,
      isTrickmode: false,
      isSelectingTimeFrame: false,
      leftTimeFrame: 0,
      rightTimeFrame: 0,
      isLive: true,
      pauseToDrag: true,
      hoverTime: 0,
      playbackRate: 1
    }),
    videoManager: VideoManager = {
      elements: [],
      state: videoState
    },
    isLive = computed(() => {
      if (!videoManager.state.isLive && !webRTCVodTimeSent.value) {
        context.root.$store.dispatch("setGSTWebRTCVodTimeSent", true);
      }

      return videoManager.state.isLive;
    }),
    currentDisplayTime = computed(() => {
      if (videoManager.state.isLive) {
        return _epochToHHMMSS(videoManager.state.currentTime);
      } else {
        return _epochToHHMMSS(videoManager.state.recordedPosition);
      }
    }),
    webRTCVodTimeSent = computed(
      () => context.root.$store.getters.gstWebRTCVodTimeSent
    ),
    lastVideoTime = ref(DEFAULT_AVERAGE_LIVE_TIME_AHEAD),
    _isOveriding = ref<boolean>(false),
    timeInterval = ref<any>(null);

  const addVideoElement = (video: HTMLMediaElement): void => {
    const vid = ref(video);
    const found = videoManager.elements.some(
      (element: HTMLMediaElement) => element.id === vid.value.id
    );

    if (videoManager.state.isPlaying) {
      vid.value.play();
      vid.value.currentTime = videoManager.state.currentTime;
    }

    if (!found) {
      videoManager.elements.push(vid.value);
    }
  };

  const removeVideoElement = (): void => {
    videoManager.elements = [];
  };

  const updateVideoState = (state: VideoState): void => {
    videoManager.state = state;
  };

  const updateCurrentTime = (time: number): void => {
    if (!lastVideoTime.value) {
      lastVideoTime.value = time;
    }

    if (videoManager.state.isLive) {
      // const lastVideoTimeDiff = time - lastVideoTime.value;

      // clearInterval(timeInterval.value);
      // timeInterval.value = null;
      videoManager.state.endTime = Date.now() / MILLISECOND;
      videoManager.state.startTime = videoManager.state.endTime - DEFAULT_RANGE;
      videoManager.state.currentTime = videoManager.state.endTime;
      videoManager.state.intervalEndTime = videoManager.state.currentTime;
      videoManager.state.intervalStartTime =
        videoManager.state.intervalEndTime - DEFAULT_INTERVAL_RANGE;
      lastVideoTime.value = time;
    } else {
      const lastVideoTimeDiff = time > 0 ? time - lastVideoTime.value : 0;

      videoManager.state.endTime = Date.now() / MILLISECOND;
      videoManager.state.startTime = videoManager.state.endTime - DEFAULT_RANGE;
      if (time > 0) {
        lastVideoTime.value = time;
      } else {
        lastVideoTime.value = null;
      }

      if (!videoManager.state.isTrickmode) {
        videoManager.state.recordedPosition += lastVideoTimeDiff;
      }

      videoManager.state.currentTime =
        videoManager.state.recordedPosition -
        videoManager.state.recordedStartTime;

      if (videoManager.state.startTime > videoManager.state.intervalStartTime) {
        videoManager.state.intervalStartTime = videoManager.state.startTime;
        videoManager.state.intervalEndTime =
          videoManager.state.intervalStartTime +
          videoManager.state.intervalRange;
      }

      if (
        videoManager.state.recordedPosition >=
        videoManager.state.intervalEndTime
      ) {
        videoManager.state.intervalEndTime =
          videoManager.state.recordedPosition;
        videoManager.state.intervalStartTime =
          videoManager.state.intervalEndTime - videoManager.state.intervalRange;
      }
    }
  };

  const timeOveride: ComputedRef<number | null> = computed(() => {
    if (_isOveriding.value) {
      context.root.$store.dispatch("setGSTWebRTCVodTimeSent", false);
    }

    return _isOveriding.value ? videoManager.state.currentTime : null;
  });

  const setTimeOveride = (): void => {
    _isOveriding.value = true;
  };

  const resetTimeOveride = (): void => {
    _isOveriding.value = false;
  };

  const _epochToHHMMSS = (time: number) => {
    const date = new Date(time * MILLISECOND),
      hour = date.getHours(),
      minute = date.getMinutes(),
      second = date.getSeconds();

    return (
      (hour < 10 ? "0" + hour : hour) +
      ":" +
      (minute < 10 ? "0" + minute : minute) +
      ":" +
      (second < 10 ? "0" + second : second)
    );
  };

  return {
    isLive,
    videoManager,
    addVideoElement,
    removeVideoElement,
    updateVideoState,
    updateCurrentTime,
    currentDisplayTime,
    timeOveride,
    setTimeOveride,
    resetTimeOveride,
    timeInterval
  };
}
