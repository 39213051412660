<template>
  <div class="VideoView">
    <router-view></router-view>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapActions, mapGetters } from "vuex";

export default {
  name: 'VideoView',
  mounted() {
    this.initiate()
  },
  computed: {
    ...mapGetters("cameraConfigStore", {}),
  },
  methods: {
    ...mapActions("cameraConfigStore", {}),
    async initiate() {
      console.log("Video View Initiated")
    }
  }
}
</script>
