import webRTCErrorStore from "./store/webRTCErrorStore";
import webRTCConnectionStore from "./store/webRTCConnectionStore";
import GSTWebRTCTiles from "./views/GSTWebRTCTiles/WebRTCTiles.vue";
import webRTCVideoPlayer from "./views/GSTWebRTCVideoPlayer/WebRTCVideoPlayer.vue";
import VueCompositionApi from "@vue/composition-api";
import VueNativeSock from "vue-native-websocket";
import { passToStoreHandler } from "./store/socketManager";

export default function install(Vue: any, options: any) {
  const storeNames = [
    {
      name: "webRTCConnectionStore",
      store: webRTCConnectionStore
    },
    {
      name: "webRTCErrorStore",
      store: webRTCErrorStore
    }
  ];

  storeNames.forEach(store => {
    if (options.store && !options.store.hasModule(store.name)) {
      options.store.registerModule(store.name, store.store);
    }
  });

  if (Vue && Vue.component) {
    if (!options.parentHasCompositionAPI) {
      Vue.use(VueCompositionApi);
    }

    /*
    // TODO: At this point user hasn't authenticated so we don't really know the tenant_id, temporarily, we are using a default from ENV
    const socketServerURL = `${process.env.VUE_APP_SIGNALSERVERPREFIX}${process.env.VUE_APP_CURATORURL}proxy-ws/${process.env.VUE_APP_TENANTID}`;
    // const socketServerURL = `${process.env.VUE_APP_SIGNALSERVERPREFIX}${process.env.VUE_APP_CURATORURL}proxy-ws/${context.root.$store.getters["authStore/getTenantID"]}`;

    Vue.use(VueNativeSock, socketServerURL, {
      reconnection: true,
      reconnectionAttempts: 10,
      reconnectionDelay: 5000,
      format: "json",
      store: options.store,
      passToStoreHandler
    });
     */

    Vue.component("GSTWebRTCVideoPlayer", webRTCVideoPlayer);
    Vue.component("GSTWebRTCTiles", GSTWebRTCTiles);
  }
}
