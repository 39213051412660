import axios from "axios";
import store from "@/store";

const state = () => ({
    cameraConfig: [],
  });

  const mutations = {
      setCameraConfig(state, data) {
          state.cameraConfig = data;
      }
  };

  const actions = {
      async getCameraConfig({ commit, dispatch }) {
        const response = await axios
            .get(`${process.env.VUE_APP_APIPROTOCOL}://${process.env.VUE_APP_WORLDURL}${store.getters["authStore/getTenantID"]}/cameras`,
                { 
                    headers: {
                        "Content-Type": "application/json",
                        "Access-Control-Allow-Origin": "*",
                        "Authorization": `Bearer ${store.getters["authStore/getAccessToken"]}`
                    }
                })
            .catch((err) => {
                console.log(err);
            });
        
        if (response && response.data) {
            commit('setCameraConfig',response.data)
            //@ts-ignore
            dispatch('addGSTConfigList', response.data)
        } else {
            commit('setCameraConfig', [])
        }
      },
      async getIceConfig({ commit, rootState }) {
        const response = await axios
            .get(`${process.env.VUE_APP_APIPROTOCOL}://${process.env.VUE_APP_WORLDURL}${store.getters["authStore/getTenantID"]}/config/ice`,
                { 
                    headers: {
                        "Content-Type": "application/json",
                        "Access-Control-Allow-Origin": "*",
                        "Authorization": `Bearer ${store.getters["authStore/getAccessToken"]}`
                    }
                })
            .catch((err) => {
                console.log(err);
            });
        
        if (response && response.data && response.data.servers) {
            rootState.webRTCConnectionStore.socket.iceServers = response.data.servers;
        } else {
            rootState.webRTCConnectionStore.socket.iceServers = [];
        }
      },
      async getCamera(context, deviceId) {
        const response = await axios
            // .get(`${process.env.VUE_APP_APIPROTOCOL}://${process.env.VUE_APP_WORLDURL}${store.getters["authStore/getTenantID"]}/cameras/${cameraId}`,
            .get(`${process.env.VUE_APP_APIPROTOCOL}://${process.env.VUE_APP_WORLDURL}${store.getters["authStore/getTenantID"]}/cameras/by-label/${deviceId}`,
                {
                    headers: {
                        "Content-Type": "application/json",
                        "Access-Control-Allow-Origin": "*",
                        "Authorization": `Bearer ${store.getters["authStore/getAccessToken"]}`
                    }
                })
            .catch((err) => {
                console.log(err);
            });
        
        if (response && response.data) {
            return response.data
        } else {
            return
        }
      },
      addGSTConfigList ( { rootState } , configList) {
        rootState.webRTCConnectionStore.socket.gst.configList = rootState.webRTCConnectionStore.socket.gst.configList.concat(configList);
      },
  };
   
  const getters = {
    getCameraConfig(state) {
      return state.cameraConfig;
    }
  };
   
   
  export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};