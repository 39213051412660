
























import { onMounted, onUnmounted, watch, computed } from "@vue/composition-api";

export default {
  props: {
    src: {
      type: Object,
      required: true
    },
    currentTime: {
      type: Number,
      required: false
    },
    showControls: {
      type: Boolean,
      required: false,
      default: false
    },
    isLive: {
      type: Boolean,
      default: true
    },
    isPlaying: {
      type: Boolean,
      default: false
    },
    videoCount: {
      type: Number,
      default: 1
    }
  },
  setup(props: any, context: any) {
    const webRTCManager: any = useWebRTCManager(context, props);

    const timeUpdateEvent = (): void => {
      const element: any = document.getElementById(
        `webrtc-video-${props.src.id}`
      );

      if (!element) {
        return;
      } else {
        if (props.currentTime) {
          element.currentTime = props.currentTime;

          // updateTime(props.currentTime);
          context.emit("resetTimeOveride");
        } else {
          const currentTime = element.currentTime;

          updateTime(currentTime);
        }
      }
    };

    const updateTime = (time: number): void => {
      if (props.videoCount === 0) {
        context.emit("timeUpdate", time);
      }
    };

    const createVideoElement = (): void => {
      const video = document.getElementById(`webrtc-video-${props.src.id}`),
        chunkVideo = document.getElementById(`chunk-video-${props.src.id}`);

      context.emit("addVideo", video);

      video.addEventListener("timeupdate", timeUpdateEvent);
      console.log("GETWEBRTCOFFER");
      webRTCManager.getWebRTCOffer(props.src.camera_label, video, chunkVideo);
    };

    // const destroyVideoElement = (): void => {
    //   webRTCManager.unmountJanusStream(props.src.id);
    // };

    // watch(
    //   () => props.src,
    //   () => {
    //     console.log("PROPS CHANGED", props.src);

    //     // context.root.$store.dispatch("reconnectJanusStream", props.src.id);
    //   }
    // );

    onMounted(() => {
      console.log("ON MOUNTED PLAYER ");
      createVideoElement();
    });

    onUnmounted(() => {
      // destroyVideoElement();
      context.emit("removeVideo");
    });

    return {
      ...webRTCManager
    };
  }
};

function useWebRTCManager(context: any, props: any): any {
  const loadingWebRTC = computed(() => {
    const video = document.getElementById(
      `chunk-video-${props.src.id}`
    ) as HTMLMediaElement;

    // return !!(video.currentTime > 0 && !video.paused && !video.ended && video.readyState > 2);
    return !video?.paused && !video?.ended && video?.readyState > 2;
  });

  const gstStartedStream = computed(() => {
    const webrtcStarted = context.root.$store.getters.gstStartedStream;

    return webrtcStarted(props.src.id);
  });

  // const unmountGSTStream = (id: number) => {
  //   console.log("UNMOUNTING", id);
  //   context.root.$store.dispatch("unmountGSTStream", id);
  // };

  const getWebRTCOffer = (id: number, video: any, chunkVideo: any) => {
    console.log("MOUNTING", id, video, chunkVideo);
    // loadingWebRTC.value = true;

    context.root.$store.dispatch("getGSTWebRTCOffer", {
      id,
      video,
      chunkVideo
    });
  };

  return {
    loadingWebRTC,
    // unmountGSTStream,
    getWebRTCOffer,
    gstStartedStream
  };
}
