<template>
  <v-container
      class="d-flex justify-center align-center"
      style="height: calc( 100vh - 48px ); width: 100%; margin-top:0px; display: flex; justify-content: center;"
  >
    <v-card
      class="mx-auto"
      width="344"
      height="294"
      outlined
      style="font-family: Avenir, Helvetica, Arial, sans-serif !important; align-self: center"
    >
      <v-card-title>Login</v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <LoginForm></LoginForm>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
// @ is an alias to /src
import LoginForm from "../components/LoginForm";

export default {
  name: 'Login',
  data() {
    return {
    }
  },
  computed: {
  },
  methods: {
  },
  components: {
    LoginForm,
  }
}
</script>
